<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card no-header>
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-input v-model="searchParm.keyword" placeholder="请输入关键字搜索"><i slot="suffix" class="el-input__icon el-icon-search"></i></el-input>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" style="margin-right: 8px;" @click="download()">下载excel</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="unitName" align="center" label="设备名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="lineName" align="center" label="归属产线" show-overflow-tooltip></el-table-column>
							<el-table-column prop="unitUser" align="center" label="包机人" show-overflow-tooltip></el-table-column>
							<el-table-column prop="unitUserTel" align="center" label="联系电话" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="启停状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ scope.row.haltStatus == 0 ? '开机' : '停机' }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="设备状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper" style="line-height: 41px;">
										{{ scope.row.alarmLevel == 0 ? '正常' : scope.row.alarmLevel == 1 ? '一级报警' : scope.row.alarmLevel == 2 ? '二级报警' : '三级报警' }}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="alarmTimes" align="center" label="当月报警次数" show-overflow-tooltip></el-table-column>
							<el-table-column prop="analysisTimes" align="center" label="当月诊断次数" show-overflow-tooltip></el-table-column>
							<el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip></el-table-column>
							<!-- <el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text">无</el-button>
								</template>
							</el-table-column> -->
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import caches from '@cache/cache.js';
export default {
	components: {
		smCard
	},
	data() {
		return {
			tableHeight: 0,
			eqName: '',
			tableData: [],
			searchParm: {
				size: 20,
				current: 1
			},
			showLookDialog: false,
			showEditDialog: false,
			editObj: {}
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		list() {
			this.$get('/backend-api/eqp/set/unit/query/evaluate', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			}
		},
		download() {
			let url = this.baseURL + '/backend-api/eqp/set/unit/query/evaluate/download?ent_id='+caches.user.get().entId;
			if(this.searchParm.keyword){
				url+='&keyword='+this.searchParm.keyword
			}
			// //访问路径直接下载
			window.location.href = url;
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
